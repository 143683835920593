.Enroll-root {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 100px;
}

.Enroll-btn {
  margin-top: 10px;
}

.Enroll-form {
  font-size: 16px;
}

.Enroll-formGroup {
  display: flex;
  flex-direction: column;
  margin: 20px 0 14px 0;
  width: 400px;
}

.Enroll-formGroup label {
  font-weight: 900;
}

.Enroll-subtitle {
  font-size: 16px;
  width: 500px;
}

.Enroll-title {
  font-size: 40px;
  margin-bottom: 10px;
}

@media (max-width: 700px) {
  .Enroll-subtitle, .Enroll-form, .Enroll-formGroup {
    width: 300px;
  }
}
