table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 60%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.Tuition-root {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 100px;
}

.Tuition-desc {
  margin: 20px 100px;
}

.Tuition-subtitle {
  font-size: 18px;
  font-weight: 900;
  margin-top: 50px;
}

.Tuition-title {
  font-size: 40px;
  margin-bottom: 10px;
}

@media (max-width: 700px) {
  .Tuition-desc {
    width: 300px;
  }
}
