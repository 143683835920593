.App {
  /* text-align: center; */
}

.App-header {
  background-color: #000;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  z-index: 10;
}

.App-a {
  color: #fff;
  font-family: Rajdhani-Bold;
  font-size: 21px;
  margin-right: 50px;
  text-decoration: none;
}

.App-logo {
  background-image: url('../images/logo-nobkgrnd-white.png');
  background-position-x: center;
  background-size: cover;
  height: 30px;
  margin: 14px;
  width: 100px;
}

.testblock {
  background-color: #E9E7E6;
  color: #fff;
  display: flex;
  height: 460px;
  justify-content: center;
  margin-top: 100px;
  width: 100%
}

.testimage {
  background-image: url('../images/piano1.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  width: 60%
}

.testblock2 {
  background-color: #E9E7E6;
  color: #fff;
  display: flex;
  height: 460px;
  justify-content: center;
  margin: 100px 0 0 50px;
  width: 50%
}

.testimage2 {
  background-image: url('../images/piano2.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #5f4d4d;
  margin: 40px;
  width: 100%
}

.App-navigation {
  display: flex;
  flex-direction: row;
  /* font-size: calc(10px + 2vmin); */
  list-style-type: none;
}

@media (max-width: 700px) {
  .App-navigation {
    visibility: hidden;
  }

  .testimage, .testblock2, .testimage2 {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}
