.Calendar-root {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 100px;
}

.Calendar-calendar {
  background-image: url('../../images/calendar2.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  color: #5f4d4d;
  height: 700px;
  margin: 40px 0;
  width: 600px
}

.Calendar-date {
  margin: 0;
}

.Calendar-title {
  font-size: 40px;
  margin-bottom: 10px;
}

@media (max-width: 700px) {
  .Calendar-calendar {
    height: 440px;
    margin-top: 0;
    width: 360px;
  }
}
