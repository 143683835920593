.About-root {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 100px;
}

.About-policy {
  position: relative;
  width: 50%;
}

.About-title {
  font-size: 40px;
}

@media (max-width: 700px) {
  .About-policy {
    width: 300px;
  }
}
