@font-face {
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/Rajdhani-Medium.ttf');
}

@font-face {
  font-family: Rajdhani-Bold;
  font-style: normal;
  font-weight: 900;
  src: url('./fonts/Rajdhani-Bold.ttf');
}

@font-face {
  font-family: Raleway-ExtraBold;
  font-style: normal;
  font-weight: 900;
  src: url('./fonts/Raleway-ExtraBold.ttf');
}

* {
  font-family: Rajdhani, Calibri, Tahoma, sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-family: Raleway-ExtraBold, Calibri, Tahoma, sans-serif;
}
