.Contact-root {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 100px 0;
}

.Contact-btn {
  margin-top: 10px;
}

.Contact-form {
  font-size: 16px;
}

.Contact-formGroup {
  display: flex;
  flex-direction: column;
  margin: 20px 0 14px 0;
  width: 400px;
}

.Contact-formGroup label {
  font-weight: 900;
}

.Contact-subtitle {
  font-size: 16px;
  width: 500px;
}

.Contact-title {
  font-size: 40px;
  margin-bottom: 10px;
}

@media (max-width: 700px) {
  .Contact-subtitle, .Contact-form, .Contact-formGroup {
    width: 300px;
  }
}
