.Policies-root {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 100px;
}

.buttons {
  display: flex;
  flex-direction: row;
}

.Policies-policy {
  position: relative;
  width: 60%;
}

.Policies-slogan {
  font-size: 20px;
  margin-top: 0;
}

.Policies-sub-title {
  font-size: 20px;
  margin-bottom: 0;
  width: 500px;
}

.Policies-title {
  font-size: 40px;
}

@media (max-width: 700px) {
  .Policies-policy {
    width: 300px;
  }
}
