button {
  border: none;
  cursor: pointer;
  font-family: Rajdhani-Bold;
  font-size: 20px;
  height: 40px;
  width: 160px;
}

button:first-of-type {
  margin-right: 50px;
}

.Home-root {
  background-image: url('../../images/background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  height: 100vh;
  text-align: center;
}

.Home-buttons {
  display: flex;
  flex-direction: row;
}

.Home-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  top: 26%;
}

.Home-slogan {
  font-family: Rajdhani-Bold;
  font-size: 22px;
  margin-top: 0;
}

.Home-title {
  font-size: 80px;
  margin-bottom: 0;
  width: 500px;
}

@media (max-width: 700px) {
  button:first-of-type {
    margin-bottom: 20px;
  }

  .Home-buttons {
    flex-direction: column;
    padding-left: 40px;
  }

  .Home-slogan {
    font-size: 20px;
    margin-top: 12px;
  }

  .Home-title {
    font-size: 40px;
    width: 300px;
  }
}
